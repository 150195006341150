import { css, html, LitElement } from 'lit';

import { customElement } from 'lit/decorators.js';
import dayjs from '@kisters/wiski-web/common/dayjsext';
import { getCurrentApi } from '@kisters/wiski-web/api/API';
import { getRouteOptionsAndParams } from '@kisters/wiski-web/components';
import { lastIndexOf } from 'lodash-es';

@customElement('wwp-station-download-hdoe')
export default class WwpStationDownloadHdoe extends LitElement {
  static styles = css`
    .content {
      margin-top: 30px;
    }
    .row {
      display: table-row;
      line-height: 2em;
    }
    .cell {
      display: table-cell;
      padding: 0 20px;
    }
    .label,
    ki-icon {
      color: gray;
      padding-left: 30px;
    }
    .descr {
      padding: 30px;
    }
  `;

  constructor() {
    super();
    this.api = getCurrentApi();
  }

  static get properties() {
    return {};
  }

  get groups() {
    return this.station.downloads;
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['stationId']);
    this.station = await this.api.getStation(params.stationId);
    Object.assign(this, params.options);
    this.requestUpdate();
  }

  getParameter(param) {
    return this.parameterMapping[param] || param;
  }

  // eslint-disable-next-line class-methods-use-this
  getPeriod(period) {
    switch (period) {
      case '7D':
        return 'Woche';
      case '1M':
        return 'Monat';
      case '1Y':
        return 'Jahr';
      default:
        return dayjs.duration(`P${period}`).humanize();
    }
  }

  render() {
    return this.station
      ? html` <div class="content">
          <div
            class="descr"
            style="${this.groups.length ? '' : 'display:none;'}"
          >
            ${this.descr}
          </div>
          ${this.groups.map(
            field => html` <div class="row">
              <div class="cell label">
                ${this.getParameter(field.station_parameter)}
              </div>
              <div class="cell label">
                ${this.getPeriod(field.period_alias)}
              </div>
              <div class="cell">
                <a href="${this.api.getLink(`/${field.href}`)}" target="_blank"
                  ><ki-icon icon="fa fa-download"></ki-icon
                ></a>
              </div>
            </div>`,
          )}
          <div class="descr">
            ${this.moreDownloadsText}
            <a target="_blank" href="${this.moreDownloadsLink}">hier</a>
          </div>
        </div>`
      : html`<div>lade...</div>`;
  }
}
