import { customElement, property } from 'lit/decorators.js';
import { css, html, LitElement } from 'lit';
import { i18nMixin } from '@kisters/wiski-web/decorators';
import dayjs from '@kisters/wiski-web/common/dayjsext';
import { getCurrentApi } from '@kisters/wiski-web/api/API';
import '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-graph-precip';
import nls from '@kisters/wiski-web/locales/index';
import { getConfig } from '@kisters/wiski-web/components';
import { Mix } from '@kisters/wiski-web/common';

const waterlevelCfg = {
  miniGraph: true,
  showUntilEndOfDay: false,
  imageShare: 'extralarge_ltv_week.png',
  parameter_label: 'Wasserstand',
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} / ${river_name} (Wasserstand)',
  station_parameter: 'S',
  yAxisMinRange: 100,
  yAxisMin: 'w_graph_min',
  yAxisMax: 'w_graph_max',
  progTs: 'web_prognose_ts_new',
  progDuration: 'web_prognose_duration',
  forcePeriodRange: false,
  trendAb: 'web_prognose_trendab',
  additionalTsFiles: ['S/events.json', 'S/forecast.json'],
  credits: {
    text: '<span>Ungeprüfte Rohdaten<span><br>© Amt der OÖ Landesregierung',
    position: {
      x: -10,
      y: -35,
    },
    positionMobile: {
      x: -10,
      y: -15,
    },
    style: {
      fontSize: '10px',
    },
    href: 'https://www.land-oberoesterreich.gv.at/',
  },
  ranges: [
    {
      label: 'Woche',
      value: 'P7D',
      data: 'S/week.json',
      showForecast: true,
      yAxisLabel: 'Wasserstand in cm',
      timeseries: [
        {
          ts_shortname: '15m.Cmd.RelAbs.EXP',
          options: {
            type: 'area',
            fillOpacity: 0.5,
            name: 'Wasserstand',
          },
        },
      ],
      selected: true,
    },
  ],
  forecast: 'S/forecast.json',
  alarms: {
    file: ['S/alm.json', 'S/ltv.json'],
    items: [
      {
        label: 'Alarmstufen',
        tsList: ['Cmd.Voralarm', 'Cmd.Alarm1', 'Cmd.Alarm2', 'Cmd.Alarm3'],
        active: 'Wasserstand-Alarmpegel',
      },
      {
        label: 'Alle Kennzahlen',
        tsList: [
          'Cmd.HW2',
          'Cmd.HW5',
          'Cmd.HW10',
          'Cmd.HW30',
          'Cmd.HW100',
          'Cmd.NW',
        ],
        active: false,
      },
      {
        label: 'MW',
        tsList: ['Cmd.MW'],
        active: true,
      },
      {
        label: 'HW1',
        tsList: ['Cmd.HW1'],
        active: true,
      },
    ],
  },
  events: 'S/events.json',
  threshholdMappings: {
    'Cmd.Alarm1': {
      width: 2,
      color: 'rgb(255, 165, 0)',
      dashStyle: 'dash',
      label: {
        text: 'Alarmstufe 1',
        align: 'left',
        y: 12,
        x: -5,
      },
    },
    'Cmd.Alarm2': {
      width: 2,
      color: 'rgb(128, 0, 128)',
      dashStyle: 'dash',
      label: {
        text: 'Alarmstufe 2',
        align: 'left',
        y: 12,
        x: -5,
      },
    },
    'Cmd.Alarm3': {
      width: 2,
      color: 'rgba(252, 81, 47, 1)',
      dashStyle: 'dash',
      label: {
        text: 'Alarmstufe 3',
        align: 'left',
        y: 12,
        x: -5,
      },
    },
    'Cmd.Voralarm': {
      width: 2,
      color: 'rgba(255, 255, 0, 1)',
      dashStyle: 'dash',
      label: {
        text: 'Voralarm',
        align: 'left',
        y: 12,
        x: -5,
      },
    },
    'Cmd.MW': {
      width: 2,
      color: '#AAC8FF',
      dashStyle: 'dash',
      label: {
        text: 'MW',
        description: 'Mittelwasser',
      },
    },
    'Cmd.NW': {
      width: 2,
      dashStyle: 'dash',
      label: {
        text: 'NW',
        description: 'Niederwasser',
      },
    },
    'Cmd.HW1': {
      width: 2,
      color: '#0000FF',
      dashStyle: 'dash',
      label: {
        text: 'HW1',
        description: '1-jährliche Hochwasser',
      },
    },
    'Cmd.HW2': {
      width: 2,
      dashStyle: 'dash',
      label: {
        text: 'HW2',
        description: '2-jährliche Hochwasser',
      },
    },
    'Cmd.HW5': {
      width: 2,
      dashStyle: 'dash',
      label: {
        text: 'HW5',
        description: '5-jährliche Hochwasser',
      },
    },
    'Cmd.HW10': {
      width: 2,
      dashStyle: 'dash',
      label: {
        text: 'HW10',
        description: '10-jährliche Hochwasser',
      },
    },
    'Cmd.HW30': {
      width: 2,
      dashStyle: 'dash',
      label: {
        text: 'HW30',
        description: '30-jährliche Hochwasser',
      },
    },
    'Cmd.HW100': {
      width: 2,
      dashStyle: 'dash',
      label: {
        text: 'HW100',
        description: '100-jährliche Hochwasser',
      },
    },
  },
};

const watertempCfg = {
  miniGraph: true,
  showUntilEndOfDay: false,
  imageShare: 'extralarge_wt_week.png',
  yAxisLabel: 'Wassertemperatur in °C',
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} / ${river_name} (Wassertemperatur)',
  tableOpen: false,
  parameter_label: 'Wassertemperatur',
  station_parameter: 'WT',
  forcePeriodRange: false,
  numberFormat: {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },
  credits: {
    text: '<span>Ungeprüfte Rohdaten<span><br>© Amt der OÖ Landesregierung',
    position: {
      x: -10,
      y: -35,
    },
    style: {
      fontSize: '10px',
    },
    href: 'https://www.land-oberoesterreich.gv.at/',
  },
  yAxisMinRange: 8,
  ranges: [
    {
      label: 'Woche',
      value: 'P7D',
      data: 'WT/week.json',
      selected: true,
      yAxisLabel: 'Wassertemperatur in °C',
      timeseries: [
        {
          ts_shortname: '15m.Cmd.EXP',
          options: {
            tooltip: {
              valueDecimals: 1,
            },
            name: 'Wassertemperatur',
            color: 'red',
          },
        },
      ],
    },
  ],
};

const lufttempCfg = {
  miniGraph: true,
  showUntilEndOfDay: false,
  yAxisLabel: 'Lufttemperatur in °C',
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} (Lufttemperatur)',
  tableOpen: false,
  parameter_label: 'Lufttemperatur',
  station_parameter: 'AT',
  yAxisMinRange: 8,
  forcePeriodRange: false,
  numberFormat: {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },
  credits: {
    text: '<span>Ungeprüfte Rohdaten<span><br>© Amt der OÖ Landesregierung',
    position: {
      x: -10,
      y: -35,
    },
    style: {
      fontSize: '10px',
    },
    href: 'https://www.land-oberoesterreich.gv.at/',
  },
  ranges: [
    {
      label: 'Woche',
      value: 'P7D',
      data: 'AT/week.json',
      selected: true,
      yAxisLabel: 'Lufttemperatur in °C',
      timeseries: [
        {
          ts_shortname: 'Cmd.P.DEF',
          options: {
            color: 'rgba(203,29,47)',
            tooltip: {
              valueDecimals: 1,
            },
            name: 'Lufttemperatur',
          },
        },
      ],
    },
  ],
};

const niederschlagCfg6h = {
  miniGraph: true,
  showUntilEndOfDay: false,
  filterAccuracy: 66,
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} (Niederschlag)',
  tableOpen: false,
  yAxisLabelSum: 'Summenlinie in mm',
  parameter_label: 'Niederschlag',
  station_parameter: 'Precip',
  chartType: 'column',
  credits: {
    text: '<span>Ungeprüfte Rohdaten<span><br>© Amt der OÖ Landesregierung',
    position: {
      x: -10,
      y: -35,
    },
    style: {
      fontSize: '10px',
    },
    href: 'https://www.land-oberoesterreich.gv.at/',
  },
  numberFormat: {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },
  yAxisMinRange: 5,
  ranges: [
    {
      label: '6 Stunden',
      value: 'PT6H',
      data: 'day.json',
      selected: true,
      yAxisLabel: 'Niederschlag in mm pro 15 Minuten',
    },
  ],
};

const niederschlagCfg24h = {
  miniGraph: true,
  showUntilEndOfDay: false,
  filterAccuracy: 66,
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} (Niederschlag)',
  tableOpen: false,
  yAxisLabelSum: 'Summenlinie in mm',
  parameter_label: 'Niederschlag',
  station_parameter: 'Precip',
  chartType: 'column',
  credits: {
    text: '<span>Ungeprüfte Rohdaten<span><br>© Amt der OÖ Landesregierung',
    position: {
      x: -10,
      y: -35,
    },
    style: {
      fontSize: '10px',
    },
    href: 'https://www.land-oberoesterreich.gv.at/',
  },
  numberFormat: {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },
  yAxisMinRange: 5,
  ranges: [
    {
      label: '24 Stunden',
      value: 'PT24H',
      data: 'day.json',
      selected: true,
      yAxisLabel: 'Niederschlag in mm pro 15 Minuten',
    },
  ],
};

const niederschlagCfg48h = {
  miniGraph: true,
  showUntilEndOfDay: false,
  filterAccuracy: 66,
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} (Niederschlag)',
  tableOpen: false,
  yAxisLabelSum: 'Summenlinie in mm',
  parameter_label: 'Niederschlag',
  station_parameter: 'Precip',
  chartType: 'column',
  credits: {
    text: '<span>Ungeprüfte Rohdaten<span><br>© Amt der OÖ Landesregierung',
    position: {
      x: -10,
      y: -35,
    },
    style: {
      fontSize: '10px',
    },
    href: 'https://www.land-oberoesterreich.gv.at/',
  },
  numberFormat: {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },
  yAxisMinRange: 5,
  ranges: [
    {
      label: '48 Stunden',
      value: 'P2D',
      data: 'day.json',
      selected: true,
      yAxisLabel: 'Niederschlag in mm pro 15 Minuten',
    },
  ],
};

const grundwasserCfg = {
  miniGraph: true,
  forcePeriodRange: false,
  showUntilEndOfDay: false,
  zoomType: null,
  favButton: true,
  imageShare: 'extralarge_gws_month.png',
  tableOpen: true,
  parameter_label: 'Grundwasserstand',
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} (Grundwasserstand)',
  station_parameter: 'GWLVL',
  yAxisLabel: 'Grundwasserstand in m ü.A.',

  qCodeExclude: [250],
  credits: {
    text: '<span>Ungeprüfte Rohdaten</span><br>© Amt der OÖ Landesregierung',
    position: {
      x: -10,
      y: -35,
    },
    positionMobile: {
      x: -10,
      y: -15,
    },
    style: {
      fontSize: '10px',
    },
    href: 'https://www.land-oberoesterreich.gv.at/',
  },
  ranges: [
    {
      selected: true,
      label: 'Monat',
      value: 'P1M',
      data: 'GWLVL/week.json',
      tableTemplate: ['h.Cmd.GWS.Web'],
      timeseries: [
        {
          ts_shortname: 'h.Cmd.GWS.Web',
          options: {
            tooltip: {
              valueSuffix: ' m ü.A.',
            },
            name: 'Grundwasserstand',
          },
        },
        {
          ts_shortname: 'Cmd.LTV.DMean',
          options: {
            tooltip: {
              valueSuffix: ' m ü.A.',
              xDateFormat: '%d.%m.%Y',
            },
            opacity: '0.3',
            name: 'langjähr. Mittel',
          },
        },
        {
          ts_shortname_min: 'Cmd.LTV.DMin',
          ts_shortname_max: 'Cmd.LTV.DMax',
          options: {
            lineColor: 'rgba(255,255,255,0)',
            tooltip: {
              valueSuffix: ' m ü.A.',
              xDateFormat: '%d.%m.%Y',
            },
            name: 'langjähr. Minimum - Maximum',
          },
        },
      ],
    },
  ],
  alarms: {
    file: ['GWLVL/ltv.json'],
    items: [
      {
        label: 'Kennzahlen',
        tsList: ['Cmd.HGW', 'Cmd.MGW', 'Cmd.NGW'],
        active: true,
      },
    ],
  },
  threshholdMappings: {
    'Cmd.HGW': {
      width: 2,
      color: 'rgb(0, 0, 255)',
      label: {
        text: 'HGW',
        description: 'Hoher Grundwasserstand',
        align: 'right',
        y: 12,
        x: -5,
      },
    },
    'Cmd.MGW': {
      width: 2,
      color: 'rgb(0, 136, 0)',
      label: {
        text: 'MGW',
        description: 'Mittlerer Grundwasserstand',
        align: 'right',
        y: 12,
        x: -5,
      },
    },
    'Cmd.NGW': {
      width: 2,
      color: 'rgba(255, 136, 68, 1)',
      label: {
        text: 'NGW',
        description: 'Niedriger Grundwasserstand',
        align: 'right',
        y: 12,
        x: -5,
      },
    },
  },
};

@customElement('ki-map-popup-hdoe')
export default class KiMapPopupHdoe extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  static styles = css`
    :host {
      display: block;
      z-index: 100;
      max-width: 360px;
      background: white;
      overflow: hidden;
    }
    .station-name {
      padding-right: 5px;
      padding-left: 10px;
      padding-top: 10px;
      font-size: 1.2em;
    }

    hr {
      margin: 5px 0;
    }
    .table {
      display: table;
      line-height: 1.5em;
    }
    .row {
      display: table-row;
    }
    .cell {
      display: table-cell;
      padding: 0 10px;
      min-width: 50px;
      color: gray;
    }
    .label {
      color: #4a4a49;
    }

    ki-wwp-graph,
    ki-wwp-graph-precip,
    .graph {
      height: 240px;
      width: 360px;
    }

    .remark {
      padding: 2px 10px;
      color: red;
    }
  `;

  @property({ type: Array })
  stations = [];

  stationRemark = '';

  config = getConfig();

  api = getCurrentApi();

  formatter = new Intl.NumberFormat('de', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  valFormatter(val) {
    return val === 0 ? val : this.formatter.format(val);
  }

  _stationTmpl(station) {
    let graphComp = html`<div></div>`;
    const parameter = station.stationparameter_name;
    /* HDOE uses URLs as remarks sometime, but those should not be shown in popup */
    if (
      station &&
      this.config?.remarkAttr &&
      !station[this.config?.remarkAttr].includes('<a')
    )
      this.stationRemark = station[this.config?.remarkAttr] || '';

    let title = `${station.station_name} / ${station.river_name} (${station.stationparameter_name})`;
    if (parameter === 'Wasserstand') {
      this.formatter = new Intl.NumberFormat('de', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      graphComp = html`<ki-wwp-graph-hdoe-fcst
        .options="${waterlevelCfg}"
        stationId="${station.station_id}"
      ></ki-wwp-graph-hdoe-fcst>`;
    } else if (parameter === 'Wassertemperatur') {
      graphComp = html`<ki-wwp-graph
        .options="${watertempCfg}"
        stationId="${station.station_id}"
      ></ki-wwp-graph>`;
    } else if (parameter === 'Niederschlag') {
      // Differentiate between 24h, 48h and 6h difference
      if (station.ts_name.includes('48h'))
        graphComp = html`<ki-wwp-graph-precip
          .options="${niederschlagCfg48h}"
          stationId="${station.station_id}"
        ></ki-wwp-graph-precip>`;
      else if (station.ts_name.includes('24h'))
        graphComp = html`<ki-wwp-graph-precip
          .options="${niederschlagCfg24h}"
          stationId="${station.station_id}"
        ></ki-wwp-graph-precip>`;
      else if (station.ts_name.includes('6h'))
        graphComp = html`<ki-wwp-graph-precip
          .options="${niederschlagCfg6h}"
          stationId="${station.station_id}"
        ></ki-wwp-graph-precip>`;
      else
        console.warn(
          'Invalid precipitation data, please check popup or timeseries config',
        );

      title = `${station.station_name} (${station.stationparameter_name})`;
    } else if (parameter === 'Lufttemperatur') {
      graphComp = html`<ki-wwp-graph
        .options="${lufttempCfg}"
        stationId="${station.station_id}"
      ></ki-wwp-graph>`;
      title = `${station.station_name} (${station.stationparameter_name})`;
    } else if (parameter === 'GWS') {
      station.ts_unitsymbol = 'm ü.A.';
      graphComp = html`<ki-wwp-graph
        .options="${grundwasserCfg}"
        stationId="${station.station_id}"
      ></ki-wwp-graph>`;

      title = `${station.station_name} (Grundwasserstand)`;
    }

    return html`<div class="station-name">${title}</div>
      <div class="remark">${this.stationRemark}</div>
      <hr style="width:100%" />
      <div class="table">
        <div class="row">
          <div class="cell label">${this.i18n.t(parameter)}</div>
          <div class="cell">
            ${station.ts_value != null
              ? `${this.valFormatter(station.ts_value)} ${
                  station.ts_unitsymbol
                }`
              : '-'}
          </div>
        </div>
        <div class="row">
          <div class="cell label">${this.i18n.t('last_measure')}</div>
          <div class="cell">
            ${(station.timestamp && dayjs(station.timestamp).format('L LT')) ||
            '-'}
          </div>
        </div>
      </div>
      <div class="graph">${graphComp}</div>
      <br /> `;
  }

  render() {
    return this.stations
      ? html`${this._stationTmpl(this.stations[0])}`
      : html`<div></div>`;
  }
}
