/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-nested-ternary */
import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { initConfig, getRoutes } from '@kisters/wiski-web/components';
import { LoaderMixin } from '@kisters/wiski-web/common';
import { initAPI } from '@kisters/wiski-web/api/API';

import '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-graph-ext';
import '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-graph-precip-ext';
// Import common components here
import '../../common/components/ki-wwp-graph-hdoe-fcst';
import '../../common/components/ki-wwp-graph-hdoe-fcst-ext';
import '../../common/components/ki-map-popup-hdoe';
import '../../common/components/wwp-station-download-hdoe';
import '../../common/components/wwp-hdoe-kennzahlen';
import '../../common/components/wwp-hdoe-querprofil';
import '../../common/components/wwp-hdoe-hochwasserbericht';
import '../../common/components/wwp-hdoe-bericht-wasserstand';
import '../../common/components/wwp-hdoe-start';
import '../../common/components/ww-hdoe-hydrochar';

import importActions from './import-actions/import-actions';

// TODO use lib without compile might be better.
// can consider all ki-wcp project with build version instead import source and recompile it

@customElement('ww-app-index')
export default class wwAppIndex extends LoaderMixin(LitElement) {
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
    }
  `;

  constructor() {
    super();
    this.configLoaded = false;
    this.configLocation = './config/app.json';
  }

  render() {
    if (!this.configLoaded) {
      return this._renderLoader();
    }
    // language=html
    return html` <ki-app-index .route="${getRoutes()}"></ki-app-index> `;
  }

  firstUpdated() {
    this.promiseLoader(
      initConfig(this.configLocation, importActions).then(config => {
        initAPI({ dataPath: config.dataPath });
        this._config = config;
        this.configLoaded = true;
      }),
    );
  }
}
