import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getCurrentApi } from '@kisters/wiski-web/api/API';
import { orderBy } from 'lodash-es';

/**
 * A component to display Hochwasserberichte (flood reports) using the browser's native PDF viewer.
 */
@customElement('wwp-hdoe-hochwasserbericht')
export class WwpHdoeHochwasserbericht extends LitElement {
  static styles = css`
    :host {
      display: block;
      height: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  `;

  api = getCurrentApi();

  content;

  renderPDF() {
    const url = `${this.api.dataPath}/${this.content}`;

    return this.content
      ? html`<iframe title="Hochwasserbericht" src="${url}"></iframe>`
      : html`Keine Inhalte verfügbar`;
  }

  async onAfterEnter() {
    this.fetchData();
  }

  /**
   * Fetches PDF documents and updates the `content` property with the most recent or default report URL.
   */
  async fetchData() {
    this.api.getReportInfo().then(d => {
      const defaultBericht = d.all_listing.filter(
        item =>
          item.mimeType === 'application/pdf' &&
          item.name.includes(
            'documents/hochwasserbericht/default_Hochwasserbericht.pdf',
          ),
      );
      const hochwasserberichte = orderBy(
        d.all_listing.filter(
          item =>
            item.mimeType === 'application/pdf' &&
            item.name.includes('documents/hochwasserbericht/') &&
            !item.name.includes(
              'documents/hochwasserbericht/default_Hochwasserbericht',
            ),
        ),
        'lastModified',
        'desc',
      );
      if (hochwasserberichte.length) {
        this.content = hochwasserberichte[0].name;
      } else if (defaultBericht[0]) {
        this.content = defaultBericht[0].name;
      }
      this.requestUpdate();
    });
  }

  render() {
    return this.renderPDF();
  }
}
