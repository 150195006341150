import { customElement } from 'lit/decorators.js';
import { css, html, LitElement } from 'lit';
import { responsiveMixin } from '@kisters/wiski-web/decorators';
import { last, orderBy } from 'lodash-es';
import dayjs from '@kisters/wiski-web/common/dayjsext';
import { getCurrentApi } from '@kisters/wiski-web/api/API';
import {
  getRouteOptionsAndParams,
  getConfig,
} from '@kisters/wiski-web/components';
import { Mix } from '@kisters/wiski-web/common';

@customElement('wwp-hdoe-kennzahlen')
export default class WwpHdoeKennzahlen extends Mix(
  LitElement,
  responsiveMixin,
) {
  // language=CSS
  static styles = css`
    :host {
      display: block;
    }

    #box {
      margin: 20px;
    }

    h2 {
      color: black;
    }

    :host(.sm-screen) h2 {
      margin-left: 50px;
      margin-top: 15px;
    }

    .kennzahlenAnmerkungen {
      font-weight: 900;
      font-size: 20px;
    }

    .kennzahlen-table {
      margin: 10px 0;
      width: 300px;
    }

    .hidden {
      display: none;
    }

    table,
    th,
    td {
      border: 2px solid #e8e8ea;
      border-collapse: collapse;
    }

    th {
      text-align: center;
    }

    td {
      width: 50%;
      padding: 5px;
    }

    .rawdata {
      font-family: monospace;
    }
  `;

  content = 'Kennzahlen';

  alarmstufen = [];

  ereignisse = [];

  kennzahlen = [];

  alarmstufenData = [];

  ereignisseData = [];

  kennzahlenData = [];

  api = getCurrentApi();

  config = getConfig();

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['stationId']);
    this.station = await this.api.getStation(params.stationId);
    Object.assign(this, params.options);
    this.fetchData();
    console.debug('Color Kennzahlen:', this.config?.kennzahlenColor);
  }

  render() {
    return html`<div id="box">
      <h2>${this.content}</h2>

      <div
        style="color: ${this.config.kennzahlenColor || 'red'}"
        class="kennzahlenAnmerkungen"
      >
        <!-- Benutzerdefinierter Text Reiter Kennzahlen Website (WW-340) -->
        ${this?.station?.web_kennzahlen_anmerkung}
      </div>
      <div style="width: 100%">
        <div
          style="float: left"
          class="${this.alarmstufen.length ? '' : 'hidden'}"
        >
          <table class="kennzahlen-table">
            <tr>
              <th colspan="2">Alarmstufen</th>
            </tr>
            ${this.alarmstufen}
          </table>
        </div>
        <div
          style="float: left"
          class="${this.ereignisse.length ? '' : 'hidden'}"
        >
          <table class="kennzahlen-table">
            <tr>
              <th colspan="2">Ereignisse</th>
            </tr>
            ${this.ereignisse}
          </table>
        </div>
        <div
          style="float: left"
          class="${this.kennzahlen.length ? '' : 'hidden'}"
        >
          <table class="kennzahlen-table">
            <tr>
              <th colspan="2">Kennzahlen</th>
            </tr>
            ${this.kennzahlen}
          </table>
        </div>
      </div>
      <div style="width: 100%; float: right">
        <p>
          Weitere Informationen zu den Kennzahlen finden Sie in den zugehörigen
          <a href="./index.html#/html/Kennzahlen">Erklärungen.</a>
        </p>
      </div>
    </div> `;
  }

  /**
   * Fetch data and prepare view items for rendering
   */
  async fetchData() {
    const _files = {};
    await Promise.all(
      this.station.timeseries
        .filter(ts => ts.station_parameter === this.station_parameter)
        .map(async ts => {
          const fileName = last(ts.href.split('/'));
          if (this.files.includes(fileName)) {
            _files[fileName] = await this.api.getTsData(ts.href);
          }
        }),
    );

    this.alarmstufenData = _files['alm.json'];
    this.ereignisseData = _files['events.json'];
    this.kennzahlenData = _files['ltv.json'];

    /* Build Data Model */
    /**
     * Create data views
     * Invert data array to use most recent value (WW-472)
     */
    this.alarmstufenData &&
      orderBy(this.alarmstufenData, 'ts_name').forEach(i => {
        this.alarmstufen.push(html`<tr></tr>`);
        this.alarmstufen.push(html`<td>${i.ts_name.split('-')[1]}</td>`);
        if (i.rows > 0) {
          this.alarmstufen.push(
            html`<td>
              ${`${i.data.reverse()[0][1].toString()} ${
                i.web_unit_gws || i.ts_unitsymbol
              }`}
            </td>`,
          );
        } else {
          this.alarmstufen.push(html`<td>-</td>`);
        }
        this.alarmstufen.push(html`</tr>`);
      });

    this.ereignisseData &&
      this.ereignisseData[0]?.data.forEach(i => {
        this.ereignisse.push(html`<tr></tr>`);
        this.ereignisse.push(html`<td>${dayjs(i[0]).format('L')}</td>`);
        this.ereignisse.push(
          html`<td>
            ${`${i[1].toString()} ${
              this.ereignisseData[0].web_unit_gws ||
              this.ereignisseData[0].ts_unitsymbol
            }`}
          </td>`,
        );
        this.ereignisse.push(html`</tr>`);
      });

    /** Invert data array to use most recent value (WW-472) */
    this.kennzahlenData &&
      orderBy(this.kennzahlenData, 'ts_name').forEach(i => {
        this.kennzahlen.push(html`<tr></tr>`);
        this.kennzahlen.push(html`<td>${i.ts_name.split('-')[1]}</td>`);
        if (i.rows > 0) {
          this.kennzahlen.push(
            html`<td>
              ${`${i.data.reverse()[0][1].toString()} ${
                i.web_unit_gws || i.ts_unitsymbol
              }`}
            </td>`,
          );
        } else {
          this.kennzahlen.push(html`<td></td>`);
        }
        this.kennzahlen.push(html`</tr>`);
      });

    this.requestUpdate();
  }
}
