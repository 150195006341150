import { css, html, LitElement } from 'lit';

import { property, customElement } from 'lit/decorators.js';
import { last, sortBy } from 'lodash-es';
import Highcharts from 'highcharts';
import serieslabel from 'highcharts/modules/series-label';
import { getCurrentApi } from '@kisters/wiski-web/api/API';
import { getRouteOptionsAndParams } from '@kisters/wiski-web/components';

serieslabel(Highcharts);

@customElement('wwp-hdoe-querprofil')
export default class wwpHdoeQuerprofil extends LitElement {
  // language=CSS
  static styles = css`
    :host {
      height: 100%;
      overflow-y: auto;
      width: 100%;
      display: flex;
    }
    #chart-node {
      display: table-cell;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    #chart-wrapper {
      display: table-cell;
      position: relative;
      flex: 1;
    }
  `;

  api = getCurrentApi();

  @property({ attribute: false }) highcharts = Highcharts;

  static get properties() {
    return {
      content: { type: String },
      alarmstufen_data: { type: Object, attribute: false },
      ereignisse_data: { type: Object, attribute: false },
      kennzahlen_data: { type: Object, attribute: false },
      querprofil_data: { type: Object, attribute: false },
      week_data: { type: Object, attribute: false },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.numberFormatter = new Intl.NumberFormat('de', this.numberFormat || {});

    window.addEventListener('resize', () =>
      setTimeout(() => {
        this.chart && this.chart.reflow();
      }, 2200),
    );
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['stationId']);
    this.station = await this.api.getStation(params.stationId);
    Object.assign(this, params.options);
    this.fetchData();
  }

  createChart() {
    this.chart = this.highcharts.chart(
      this.renderRoot.querySelector('#chart-node'),
      this.chartOptions(),
      this.chartCreated.bind(this),
    );
  }

  _formatNumber(val) {
    return val === undefined || val === null || val === '' || Number.isNaN(val)
      ? ''
      : this.numberFormatter.format(val);
  }

  // eslint-disable-next-line class-methods-use-this
  chartOptions() {
    const self = this;
    return {
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          label: {
            enabled: true,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      title: {
        text: 'Querschnittdarstellung',
      },
      tooltip: {
        enabled: true,
        valueSuffix: ' m',
        headerFormat: 'Abstand {point.key} m<br>',
        valueDecimals: 1,
      },
      yAxis: {
        title: {
          text: 'Höhe in m',
        },
      },
      legend: {
        title: {
          text: 'Legende zum EIN/AUSKLICKEN',
        },
      },
      xAxis: {
        title: {
          text: 'Abstand in m',
        },
        labels: {
          formatter() {
            return `${self._formatNumber(this.value)}`;
          },
        },
      },
    };
  }

  addNewLineToChart(name, data, color, z, show) {
    const new_series = {};
    new_series.type = 'line';
    new_series.id = name;
    new_series.name = name;

    new_series.color = color;
    new_series.showInLegend = show;
    new_series.visible =
      name === ' HW30' || name === ' HW1' || name === 'aktueller Wasserstand';

    new_series.data = data;
    new_series.zIndex = z + 50;

    // Do not show data points
    new_series.marker = {
      enabled: false,
      states: { hover: { enabled: false }, inactive: { opacity: 1 } },
    };
    // new_series.states = {"hover": {"enabled": false, "opacity": 1}};

    this.chart.addSeries(new_series);
  }

  addNewAreaToChart(name, data, color, z) {
    const new_series = {};
    new_series.type = 'area';
    new_series.id = name;
    new_series.name = name;
    new_series.label = {
      enabled: false,
    };

    if (Array.isArray(data)) new_series.data = data;
    else {
      // Span line over whole chart
      const x_max = last(this.chart.series[0].data).x;

      const level_data = [];
      level_data.push({ x: 0, y: data });
      level_data.push({ x: x_max, y: data });
      new_series.data = level_data;
    }
    new_series.color = color;
    new_series.fillColor = color;
    new_series.zIndex = z;
    new_series.threshold = null;

    // Do not show data points
    new_series.marker = {
      enabled: false,
      states: { hover: { enabled: false }, inactive: { opacity: 1 } },
    };
    // new_series.states = {"hover": {"enabled": false, "opacity": 1}};

    this.chart.addSeries(new_series);
  }

  chartCreated(chart) {
    const e = new CustomEvent('load', {
      detail: chart,
    });
    this.dispatchEvent(e);
  }

  updated() {
    if (this.chart) {
      this.chart.reflow();
    }
  }

  _fillLineData(level) {
    const crossSectionData = this.querprofil_data[0].polygon.point;
    return crossSectionData.map(dp => ({ x: dp.x, y: level }));
  }

  render() {
    return html`<div id="chart-wrapper">
      <div id="chart-node"></div>
    </div>`;
  }

  async fetchData() {
    const _files = {};

    // TODO: Fix API call
    const files = await this.api.getTsData(
      `internet/stations/${this.station.site_no}/${this.station.station_no}/cross_section/cross_section.json`,
    );

    await Promise.all(
      this.station.timeseries
        .filter(ts => ts.station_parameter === this.station_parameter)
        .map(async ts => {
          _files[last(ts.href.split('/'))] = await this.api.getTsData(ts.href);
        }),
    );

    this.alarmstufen_data = _files['alm.json'];
    this.ereignisse_data = _files['events.json'];
    this.kennzahlen_data = _files['ltv.json'];
    this.week_data = _files['week.json'];

    this.querprofil_data = files;

    this.createChart();
    const crossSectionData = this.querprofil_data[0].polygon.point;
    // Plot profile
    this.addNewAreaToChart(
      'Gelände',
      crossSectionData,
      'rgba(164, 100, 30, 1)',
      15,
    );

    // Parse Waterlevel
    const lastlevel = last(this.week_data[0].data)[1]; // Access latest data point

    this.addNewAreaToChart(
      'aktueller Wasserstand',
      this._fillLineData(lastlevel / 100),
      'rgba(10, 67, 119, 0.5)',
      3,
    ); // cm -> m
    //  this.addNewLineToChart("aktueller Wasserstand", this._fillLineData(lastlevel/100), "rgb(10, 67, 119)", 4, true )

    // Parse Alarm
    this.alarmstufen_data.forEach(i => {
      if (i.rows > 0) {
        this.addNewLineToChart(
          i.ts_name.split('-')[1],
          this._fillLineData(i.data[0][1] / 100),
          undefined,
          5,
          true,
        ); // cm -> m
      }
    });

    // Parse Kennzahlen
    sortBy(this.kennzahlen_data, 'ts_name').forEach(i => {
      if (i.rows > 0)
        this.addNewLineToChart(
          i.ts_name.split('-')[1],
          this._fillLineData(i.data[0][1] / 100),
          undefined,
          11,
          true,
        ); // cm -> m
    });

    // Brückenunterkante
    if (this.station.web_querprofil_buk) {
      this.addNewLineToChart(
        'Brückenunterkante',
        this._fillLineData(this.station.web_querprofil_buk / 100),
        '#222222',
        12,
      ); // cm -> m
    }

    if (this.station.web_querprofil_bok) {
      this.addNewLineToChart(
        'Brückenoberkante',
        this._fillLineData(this.station.web_querprofil_bok / 100),
        '#222222',
        12,
      ); // cm -> m
    }

    this.requestUpdate();
  }
}
