import { html } from 'lit';

import { customElement } from 'lit/decorators.js';
import { until } from 'lit/directives/until.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { Mix } from '@kisters/wiski-web/common';
import { WwpOverview } from '@kisters/wiski-web/components';
import LegendFactory from '@kisters/wiski-web/components/ki-station-map/LegendFactory';
import { getCurrentApi } from '@kisters/wiski-web/api/API';
import style from './wwp-hdoe-start.css';

/**
 * This component shows the "Oberflächengewässer -> Pegel" layer.
 * "Fremdpegel" are filtered out on the start page by default (customer request, see WW-423)
 */
@customElement('wwp-hdoe-start')
export class WwpHdoeStart extends Mix(WwpOverview) {
  static styles = [WwpOverview.styles, style];

  startLayers = [
    {
      type: 'topojson',
      url: './maps/abdeckung.json',
      crs: 'EPSG:3857',
      label: 'Länder nah',
      zIndex: 29,
      selectable: false,
      visible: true,
      group: 'Layers',
      style: {
        stroke: {
          color: 'rgba(255, 255, 255, 1)',
        },
        fill: {
          color: 'rgba(255, 255, 255, 1)',
        },
      },
    },
    {
      type: 'GeoJSON',
      url: './maps/ooe.json',
      crs: 'EPSG:3857',
      label: 'ooe abdeckung',
      zIndex: 30,
      selectable: false,
      visible: true,
      group: 'Layers',
      style: {
        stroke: {
          color: 'rgba(29, 29, 29, 3)',
        },
        fill: {
          color: 'rgba(255, 255, 255, 0)',
        },
      },
    },
    {
      type: 'GeoJSON',
      url: './maps/seen.json',
      crs: 'EPSG:3857',
      label: 'Seen',
      visible: true,
      group: 'Layers',
      selectable: false,
      zIndex: 28,
      style: {
        stroke: {
          color: 'rgba(0, 77, 168, 1)',
          width: 1,
        },
        fill: {
          color: 'rgba(0, 112, 255, 1)',
        },
      },
    },
    {
      type: 'topojson',
      url: './maps/ezgs.json',
      crs: 'EPSG:3857',
      label: 'Einzugsgebiete',
      group: 'Layers',
      visible: true,
      opacity: 1,
      zIndex: 20,
      selectable: false,
      styleProperty: 'REGION',
      style: {
        Donau: {
          stroke: {
            color: 'rgba(110, 110, 110, 1.0)',
            width: 1,
          },
          fill: {
            color: 'rgba(254,190,190, 1)',
          },
        },
        Elbe: {
          stroke: {
            color: 'rgba(110, 110, 110, 1)',
            width: 1,
          },
          fill: {
            color: 'rgba(204, 204, 204, 1)',
          },
        },
        Enns: {
          stroke: {
            color: 'rgba(110, 110, 110, 1)',
            width: 1,
          },
          fill: {
            color: 'rgba(254, 232, 190, 1)',
          },
        },
        Inn: {
          stroke: {
            color: 'rgba(110, 110, 110, 1)',
            width: 1,
          },
          fill: {
            color: 'rgba(231, 254, 184, 1)',
          },
        },
        Traun: {
          stroke: {
            color: 'rgba(110, 110, 110, 1)',
            width: 1,
          },
          fill: {
            color: 'rgba(115, 178, 255, 1)',
          },
        },
      },
    },
    {
      type: 'GeoJSON',
      url: './maps/gew_grob.json',
      crs: 'EPSG:3857',
      label: 'Gewässer sehr grob',
      group: 'Layers',
      visible: true,
      selectable: false,
      styleProperty: 'SYMBOL',
      zIndex: 31,
      style: {
        '1': {
          stroke: {
            color: 'rgba(0, 77, 168, 1.0)',
            width: 4,
          },
        },
        '2': {
          stroke: {
            color: 'rgba(0, 92, 230, 1.0)',
            width: 3,
          },
        },
        '3': {
          stroke: {
            color: 'rgba(0, 112, 255, 1.0)',
            width: 1.5,
          },
        },
        '4': {
          stroke: {
            color: 'rgba(0, 169, 230, 1.0)',
            width: 1,
          },
        },
      },
    },
  ];

  api = getCurrentApi();

  // eslint-disable-next-line class-methods-use-this
  getDocHeight(doc) {
    doc = doc || document;
    const __body = doc.body;
    const __html = doc.documentElement;
    const height = Math.max(
      __body.scrollHeight,
      __body.offsetHeight,
      __html.clientHeight,
      __html.scrollHeight,
      __html.offsetHeight,
    );
    return height;
  }

  setIframeHeight(e) {
    const ifrm = e.target;
    if (ifrm) {
      let doc;
      if (ifrm.contentDocument) {
        doc = ifrm.contentDocument;
      } else {
        doc = ifrm.contentWindow ? ifrm.contentWindow.document : null;
      }
      if (doc) {
        ifrm.style.visibility = 'hidden';
        ifrm.style.height = '10px'; // reset to minimal height ...
        // IE opt. for bing/msn needs a bit added or scrollbar appears
        ifrm.style.height = `${this.getDocHeight(doc) + 4}px`;
        ifrm.style.minHeight = `${this.getDocHeight(doc) + 4}px`;

        ifrm.style.visibility = 'visible';
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get viewStart() {
    const zoom = this.classList.contains('sm-screen') ? 7 : 8;
    return {
      projection: 'EPSG:3857',
      center: [1537773, 6117425],
      maxZoom: zoom,
      minZoom: zoom,
      zoom,
      extent: [983646.5, 5836117.8, 2007460.6, 6299120.7],
    };
  }

  firstUpdated() {
    setTimeout(() => {
      // this.shadowRoot.getElementById("map").resize()
    }, 1000);
  }

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    let resize;
    this.lagebericht = fetch(
      `${
        this.api.dataPath
      }/html/Lagebericht.html?cachebust=${new Date().getTime()}`,
    ).then(r => r.text().then(t => unsafeHTML(t)));

    this.aktuelles = fetch(
      `${
        this.api.dataPath
      }/html/Aktuelles.html?cachebust=${new Date().getTime()}`,
    ).then(r => r.text().then(t => unsafeHTML(t)));
    this.iframeResize = window.addEventListener('resize', () => {
      window.clearTimeout(resize);
      resize = setTimeout(() => {
        this.setIframeHeight({
          target: this.renderRoot.getElementById('lagebericht'),
        });
        this.setIframeHeight({
          target: this.renderRoot.getElementById('aktuelles'),
        });
      }, 220);
    });
  }

  _getMaxHeight() {
    if (this.classList.contains('sm-screen')) {
      return `${this.renderRoot.host.clientHeight - 40}px`;
    }
    return 'inital';
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    window.removeEventListener('resize', this.iframeResize);
  }

  // Define the element's template
  render() {
    if (this.currentLayer !== this.layerName) {
      this.getDataLayer();
    }
    return (
      this._renderLoader() ||
      html`
        <div class="leftBorder"> </div>
        <div class="top" >
          <div class="content" style="max-height:${this._getMaxHeight()}">
             <div class="titleNode">Lagebericht</div>
             <div style="display: flex;flex:1;flex-wrap: wrap;justify-content: space-between;">
                <div>${until(
                  this.lagebericht,
                  html`<span>Lade...</span>`,
                )}</div>
               <img title="https://warnungen.zamg.at/html/de/heute/alle/at/" alt="ZAMG Warnungen" onclick="window.open('https://warnungen.zamg.at/html/de/heute/alle/at/', '_blank')" src="//warnungen.zamg.at/warnwidget/de/heute/alle/at/"/>
             </div>
          </div>
        </div>
        <div class="bottom">
          <div class="content left">
            <div class="titleNode">Aktueller Wasserstand</div>
            <div class="overlay" onclick='window.location.hash= "/overview/Wasserstand"'></div>
            <ki-station-map id="map" .view="${this.viewStart}" .layers="${
              this.startLayers
            }">
        
              <ki-stations-layer
                .tagMarkers="${this.legendFactory.markers}"
                .tagSelectedMarkers="${this.legendFactory.selectedMarkers}"
                .stations="${this.filteredStations.filter(s =>
                  [
                    'Hydrographischer Dienst',
                    'via donau - Österreichische Wasserstraßen-GmbH',
                  ].includes(s.web_betreiber),
                )}"
              >
              </ki-stations-layer>
            </ki-station-map>
          </div>
          <div class="content">
             <div class="titleNode">Aktuelles</div>
             <div>${until(this.aktuelles, html`<span>Lade...</span>`)}</div>
          </div>
        </div>
        </div>
      <div class="rightBorder"> </div>
`
    );
  }

  get legendFactory() {
    const copy = { ...this._dataLayer.config.classification };
    if (copy && copy.default && copy.default.marker) {
      copy.default.marker.radius = 10;
    }
    return new LegendFactory(copy);
  }
}
